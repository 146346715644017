.subscriptions .flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: calc(100% - 83px);
}

.text-content h1 {
  font-size: 50px;
  font-family: 'sofia-pro-soft', Helvetica, sans-serif;
  margin-bottom: 40px;
}

.text-content p {
  font-size: 20px;
}

.intro-section.trial-page .text-content {
  max-width: 50%;
}

.subscriptions .text-content {
  max-width: 40%;
}

.subscriptions .text-content ul li {
  font-size: 25px;
  font-family: 'Karlo Sans Regular', 'Helvetica Neue Light', Helvetica, Arial, sans-serif;
  margin-bottom: 10px;
  padding-left: 40px;
  position: relative;
  list-style-type: none;
}

.subscriptions .text-content ul li:before {
  content: '';
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: $tertiary-color;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
}

.subscriptions .text-content ul {
  padding-left: 0;
}

.form-container {
  padding: 40px 60px;
  background: #ffffff;
  width: 500px;
  border-radius: 10px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
  margin-bottom: 32px;
}

.form-container h2 {
  font-size: 30px;
  color: $secondary-color;
  text-align: center;
}

.form-container p {
  color: $text-color;
  margin-bottom: 20px;
  margin-top: 5px;
  font-size: 14px;
  text-align: center;
  font-family: 'sofia-pro-soft', Helvetica, sans-serif;
}

.form-container a {
  text-align: center;
}

.form-container .button {
   margin: 10px auto;
 }

.form-container label.option {
  color: $text-color;

  a {
    text-decoration: underline;
  }
}

.form-container.subscription-container {
  padding: 0;
  min-width: 640px;
  width: 500px;
  flex-shrink: 0;
  margin-left: 40px;
  h2 {
    padding-top: 20px;
  }
  h3 {
    color: $text-color;
    margin: 20px 0 10px;
    font-size: 20px;
  }
  form {
    padding: 0 40px;
  }
  form .form-item a {
    color: $primary-color;
  }
}

// Step form
.form-container .step {
  display: none;
  &.active {
    display: block;
  }
  &.step3 label:first-child {
    color: $text-color;
    font-size: 18px;
    margin-bottom: 10px;
    margin-top: 20px;
  }
  &.step3 textarea {
    margin-bottom: 20px;
  }
}

.step-indicator {
  height: 60px;
  width: 100%;
  background: $secondary-color;
  padding: 0 40px;
  display: flex;
  justify-content: space-between;
}

.step.step3 {
  margin-top: 20px;
}

.step-indicator .stage {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: .5;
  cursor: pointer;
  &.active {
    opacity: 1;
  }
}

.step-indicator .stage .circle {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: $primary-color;
  line-height: 30px;
  text-align: center;
  margin-right: 10px;
  flex-shrink: 0;
  padding-left: 2px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
}

.step-indicator .stage p {
  margin: 0;
  color: #ffffff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
  font-family: 'sofia-pro-soft', Helvetica, sans-serif;
}

.button-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid $grey-color;
  padding: 20px 0;
  margin-top: 10px;
  margin-bottom: 20px;
  .link {
    margin-top: 0;
  }
  .button {
    margin: 0;
  }
  .link-box {
    display: flex;
  }
}

.two-column-inputs {
  display: flex;
}

.two-column-inputs .form-item {
  margin: 0 10px;
  max-width: 50%;
  width: 50%;
}

.two-column-inputs .form-item:first-child {
  margin-left: 0;
}

.two-column-inputs .form-item:last-child {
  margin-right: 0;
}

.form-container .form-item input[type="checkbox"] {
  opacity: 0;
  visibility: inherit;
  left: auto;
}