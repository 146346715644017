.main-footer {
  padding: 20px 0;
  background: $primary-color;
  color: #ffffff;
}

.main-footer a {
  color: #ffffff;
}

.main-footer .footer-columns {
  display: flex;
  justify-content: space-between;
}

.main-footer .footer-columns .column h2 {
  font-size: 25px;
  font-family: 'sofia-pro-soft', Helvetica, sans-serif;
  padding-top: 20px;
  margin-bottom: 20px;
}

.main-footer .footer-columns .column ul {
  padding-left: 0;
}

.main-footer .footer-columns .column ul li {
  margin-bottom: 5px;
  list-style-type: none;
}

.main-footer .footer-columns .column .muted {
  margin-top: 20px;
}

.main-footer address p,
.main-footer address a {
  font-family: 'Karlo Sans Regular', 'Helvetica Neue Light', Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 1.4;
}

.main-footer address a:first-child {
  display: block;
  margin-top: 20px;
}

.main-footer img {
  height: 40px;
  margin-right: 10px;
}