// Intro section
.intro-text {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.intro-text h1,
.intro-text h2 {
  font-size: 60px;
  font-family: 'sofia-pro-soft', Helvetica, sans-serif;
}

.intro-text h2 {
  font-size: 35px;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.intro-text .circle {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background: #78d4b2;
  margin: 0 10px;
}

.intro-text p {
	width: 100%;
}

.button-section {
  margin-top: 40px;
  display: flex;
  position: relative;
}

.button-section .button {
  margin: 0 10px;
}

.button-section .tilskudsbadge {
  width: 120px;
  height: 140px;
  position: absolute;
  top: 50%;
  right: -120px;
  transform: translateY(-50%);
  background: url(../images/tilskudsbadge-bg.svg) no-repeat center center;
  background-size: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px;
}

.button-section .tilskudsbadge p {
  font-size: 14px;
  text-align: left;
  text-transform: uppercase;
  padding-left: 6px;
}

// Sales points section
.sales-points {
  display: flex;
  justify-content: space-between;
  margin-bottom: 80px;
}

.sales-points .column {
  text-align: center;
  max-width: 340px;
}

.sales-points img {
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  height: 300px;
  width: 300px;
  border-radius: 50%;
}

.sales-points h2 {
  font-size: 25px;
  margin-top: 20px;
}

.sales-points p {
  margin-top: 20px;
}

// Testimonial section
.testimonial-section {
  width: 100%;
  background: $secondary-color;
  height: auto;
  padding: 40px 0;
}

.testimonial-section .container {
  //display: flex;
  //justify-content: center;
  //align-items: center;
  height: 100%;
}

// Frontpage slider
#frontpage-slider p {
  padding: 0 100px;
  text-align: center;
  max-width: 100%;
  font-size: 18px;
  line-height: 2;
  color: #ffffff;
}

#frontpage-slider .slide p.testimonial:before {
  content: '';
  height: 29px;
  width: 40px;
  display: inline-block;
  margin-right: 20px;
  background: url(../images/quote-mark.svg) no-repeat center bottom;
  background-size: 100%;
}

#frontpage-slider .author {
  margin-top: 20px;
  line-height: 1;
}
#frontpage-slider .slide {

  min-height: 160px;
  max-width: 100%;
}

body.front .testimonial-section .container {
  height: 100%;
  max-width: 1200px;
}

.bx-wrapper .bx-viewport {
  background: none;
  border: none;
  box-shadow: none;
}

.bx-wrapper {
  margin: 0;
}

.bx-wrapper .bx-controls-direction a {
  width: 44px;
  height: 44px;
}

.bx-wrapper .bx-prev,
.bx-wrapper .bx-next {
  left: 0;
  height: 44px;
  width: 44px;
  background-size: 44px;
  background: url(../images/prev-slider-icon.svg) no-repeat center center;
}

.bx-wrapper .bx-next {
  background: url(../images/next-slider-icon.svg) no-repeat center center;
  right: 0;
  left: inherit;
}

.bx-wrapper .bx-next:hover {
  background-position: inherit;
}

// Hide the pager on larger screens
.bx-wrapper .bx-pager {
  display: none;
}

// Cta section
.cta-section {
  margin: 80px 0;
}

.cta-section .container {
  display: flex;
}

.image-container img {
  max-width: 100%;
  height: auto;
}

.cta-section .text-box {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding-left: 100px;
  width: 50%;
  max-width: 50%;
}

.cta-section .image-box {
  max-width: 50%;
}

.cta-section .text-box h1 {
  font-size: 30px;
  margin-bottom: 20px;
}

.cta-section .text-box .button {
  width: 220px;
  margin: 0 auto;
}

.cta-section .contact-info {
  margin-top: 20px;
}

.cta-section .contact-info a {
  font-size: 18px;
  color: $text-color;
  font-family: 'Karlo Sans Regular';
}

.notice-box {
  display: none;
  justify-content: center;
  align-items: center;
  min-height: 60px;
  font-size: 16px;
  text-align: center;
  width: 100%;
  background: $tertiary-color;
  color: #ffffff;
  padding: 16px;

  &.shown {
    display: flex;
  }
  p {
    //line-height: 60px;
  }
  a {
    color: #ffffff;
    text-decoration: underline;
  }
  &.uni-c {
    display: flex;
  }
}