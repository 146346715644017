// Colors
$primary-color: #4c7aa6;
$secondary-color: #47ad86;
$tertiary-color: #e36e39;
$text-color: #262626;
$grey-color: #e1e1e1;

// Typography
//@font-face {
//  font-family: 'Cambridge Regular';
//  src: url('../fonts/cambridge_regular.otf');
//  src: url('../fonts/cambridge_regular.woff') format('woff');
//}
//
//@font-face {
//  font-family: 'Cambridge Light';
//  src: url('../fonts/cambridge_light.otf');
//  src: url('../fonts/cambridge_light.woff') format('woff');
//}

@font-face {
  font-family: "sofia-pro-soft";
  src:  url("../fonts/sofia-pro-soft-light.woff2") format("woff2"),
        url("../fonts/sofia-pro-soft-light.woff") format("woff"),
        url("../fonts/sofia-pro-soft-light.otf") format("opentype");
  font-style: normal;
  font-display: block;
}

@font-face {
  font-family: 'Karlo Sans Regular';
  src: url('../fonts/karlosans_regular.eot');
  src: url('../fonts/karlosans_regular.woff') format('woff');
}

@font-face {
  font-family: 'Karlo Sans Light Italic';
  src: url('../fonts/karlosans_italic.eot');
  src: url('../fonts/karlosans_italic.woff') format('woff');
}

@font-face {
  font-family: 'Karlo Sans Bold';
  src: url('../fonts/karlosans_bold.eot');
  src: url('../fonts/karlosans_bold.woff') format('woff');
}

body {
  font-family: 'sofia-pro-soft', Helvetica, sans-serif;
  letter-spacing: 0.05em;
  color: $text-color;
  position: relative;
}

body.menu-open {
  overflow: hidden;
}

p {
  font-family: 'Karlo Sans Regular', 'Helvetica Neue Light', Helvetica, Arial, sans-serif;
  line-height: 1.5;
}

.main-content p {
  margin-bottom: 20px;
}

a {
  text-decoration: none;
  color: $primary-color;
}

i, em {
  font-family: 'Karlo Sans Light Italic';
}

strong, b {
  font-family: 'Karlo Sans Bold';
}

small {
  font-size: 70%;
}

ul {
  padding-left: 40px;
}

ul li {
  list-style-type: circle;
}

.main-content ul {
  padding-left: 40px;
  margin: 0 0 20px;
}

.main-content ul li {
  list-style-type: circle;
  margin-bottom: 5px;
  line-height: 1.5;
  font-family: 'Karlo Sans Regular', 'Helvetica Neue Light', Helvetica, Arial, sans-serif;
}

.main-content ol {
  margin: 0 0 20px;
}

.main-content ol li {
  margin-bottom: 5px;
  font-family: 'Karlo Sans Regular', 'Helvetica Neue Light', Helvetica, Arial, sans-serif;
  line-height: 1.5;
}

.main-content ul li:last-child {
  margin-bottom: 0;
}

// Misc.
$default-transition: .25s;