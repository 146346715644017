@media screen and (max-width: 1368px) {
  .form-container.subscription-container {
    padding: 0;
    min-width: inherit;
    margin-left: 10%;
    width: 50%;
  }
  .step-indicator {
    padding: 0 20px;
  }
  .form-container .link {
    font-size: 14px;
  }
}

@media screen and (max-width: 1200px) {
  // Navigation stuff
  .responsive-navigation {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 4;
    width: 280px;
    background: white;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    transform: translate3d(100%, 0, 0);
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
    transition: transform $default-transition cubic-bezier(.42, 0, .58, 1);
    &.menu-shown {
      transform: translate3d(0, 0, -1000px);
    }
  }
  .modal-overlay {
    transition: opacity $default-transition cubic-bezier(.42, 0, .58, 1);
    height: 200%;
    transform: translateZ(-1001px);
    &.overlay-shown {
      visibility: visible;
      opacity: 1;
    }
  }
  .main-navigation {
    display: none;
  }
  .responsive-navigation li {
    text-transform: uppercase;
  }
  .responsive-navigation .menu a {
    color: $text-color;
  }
  .responsive-navigation .menu li {
    width: 100%;
    border-bottom: 1px solid $grey-color;
    margin-left: 0;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .responsive-navigation .menu:first-child {
    padding-top: 60px;
    padding-left: 0;
  }
  .responsive-navigation .menu li.expanded {
    padding-top: 20px;
    border-top: 1px solid $grey-color;
    height: auto;
    display: block;
    text-align: center;
  }
  .responsive-navigation .menu li.expanded > a:before {
    background-image: url(../images/arrow-down-alt.svg);
  }
  .responsive-navigation .menu .menu {
    position: static;
    visibility: visible;
    opacity: 1;
    min-width: 100%;
    background: none;
    border-radius: 0;
    padding: 10px 0;
  }
  .responsive-navigation .menu .menu li:first-child {
    padding: 0;
  }
  .responsive-navigation .menu li:last-child {
    margin-right: 0;
  }
  .responsive-navigation .menu .menu li {
    padding-left: 0;
    list-style-type: none;
    font-size: 14px;
    border-bottom: 0;
    height: 40px;
    text-transform: none;
  }
  .responsive-navigation .has-button {
    padding: 20px;
    border-bottom: 1px solid $grey-color;
  }
  .responsive-navigation .has-button a.button {
    margin: 0;
    width: 100%;
  }
  .main .menu:before,
  .main .menu li a:before,
  .main .menu li.active:after {
    display: none;
  }
  .responsive-navigation .menu .has-button {
    height: 80px;
  }
  .responsive-navigation a.button {
    margin: 10px 0;
    color: #ffffff;
  }
  .responsive-items {
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  .responsive-items .box {
    display: flex;
  }
  .responsive-menu-button {
    display: block;
  }
  .has-button.responsive-version {
    display: block;
  }
  .close-responsive-menu-button {
    display: block;
    position: absolute;
    top: 10px;
    left: 10px;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  }
  // Sales points section
  .sales-points .column {
    text-align: center;
    max-width: 30%;
    width: 30%;
  }
  .sales-points img {
    height: auto;
    max-width: 100%;
  }
  .sales-points h2 {
    font-size: 20px;
  }
  .subscriptions .flex-container {
    flex-direction: column;
    justify-content: flex-start;
  }
  .intro-section.subscriptions {
    height: 100%;
  }
  .intro-section.subscriptions .text-content {
    max-width: 100%;
    margin: 40px 0;
  }
  .form-container.subscription-container {
    width: 80%;
    margin-bottom: 80px;
    margin-left: 0;
  }
  .container {
    padding: 0 80px;
  }
}

@media screen and (max-width: 1024px) {
  body {
    -webkit-overflow-scrolling: touch;
  }
  p, .link,
  .main-content ol li,
  .main-content ul li {
    font-size: 14px;
  }
  .cta-section .text-box {
    padding-left: 40px;
  }
  // Free trial page
  .text-content h1 {
    font-size: 45px;
    margin-bottom: 20px;
  }
  input[type=text],
  input[type=email],
  input[type=tel] {
    height: 40px;
    font-size: 16px;
    margin: 5px 0;
  }
  textarea,
  .form-textarea-wrapper textarea {
    font-size: 16px;
  }
  // Subscription subpages
  .subscriptions .text-content ul li {
    font-size: 25px;
  }
  .step-indicator {
    margin-top: 20px;
    justify-content: space-around;
  }
  .form-container.subscription-container h2 {
    padding-top: 20px;
  }
  .form-container {
    padding: 20px 40px;
    width: 80%;
    margin-bottom: 65px;
  }
  .form-container p {
    margin-bottom: 0;
  }
  // Subpages
  .intro-section.subpage {
    height: 35vh;
  }
  .content-section .main-content {
    margin-left: 40px;
  }
  .content-section .image-container {
    justify-content: flex-start;
    flex-flow: inherit;
  }
  .content-section .image-container .image-box {
    margin: 0;
    flex-shrink: 1;
  }
  .content-section .image-container .image-box:first-child {
    margin-right: 10px;
  }
  .content-section .image-container .image-box:last-child {
    margin-left: 10px;
  }
  .content-section .image-container .image-box img {
    height: auto !important;
  }
}

@media screen and (max-width: 768px) {
  .container {
    padding: 0 40px;
  }
  // Intro section
  .intro-section {
    height: 70vh;
  }
  // Sales points section
  .sales-points {
    flex-flow: row wrap;
    justify-content: center;
    margin-bottom: 40px;
  }
  .sales-points .column {
    width: 50%;
    max-width: none;
    margin-bottom: 40px;
  }
  .sales-points .column:last-child {
    margin-bottom: 0;
  }
  // Testimonial section
  .testimonial-section .container {
    padding: 0;
  }
  .bx-wrapper .bx-prev {
    left: 20px !important;
  }
  .bx-wrapper .bx-next {
    right: 20px !important;
  }
  // CTA section
  .cta-section {
    margin: 40px 0;
  }
  .cta-section .container {
    flex-direction: column;
  }
  .cta-section .container .image-box {
    order: 2;
  }
  .cta-section .text-box {
    padding-left: 0;
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px;
  }
  .cta-section .image-box {
    max-width: 100%;
  }
  .cta-section .text-box h1 {
    font-size: 25px;
  }
  // Subscription subpages
  .subscriptions .flex-container {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
  }
  .text-content {
    max-width: 100%;
    margin-bottom: 40px;
  }
  .form-container {
    width: 100%;
    padding: 20px;
  }
  .form-container.subscription-container {
    width: 100%;
  }
  .step-indicator {
    justify-content: space-between;
    padding: 0 40px;
  }
  .intro-section.subpage {
    height: 25vh;
  }
  // Sidebar on subpages
  .subpage-sidebar {
    width: 220px;
  }
  .sidebar-card .button {
    min-width: 100%;
    font-size: 14px;
  }
  .sidebar-card {
    padding: 20px;
  }
  // Contact page specific
  .contact-page .link-group a {
    font-size: 18px;
  }
  .contact-page .address-box address {
    font-size: 14px;
  }
}

@media screen and (max-width: 767px) {
  .container {
    padding: 0 20px;
  }
  // Intro section
  .intro-text h1 {
    font-size: 45px;
  }
  .intro-text h2 {
    font-size: 25px;
  }
  .button-section {
    flex-direction: column;
    margin-top: 20px;
  }
  .button-section .tilskudsbadge {
    display: none;
  }
  .button-section .button {
    margin: 0 0 10px;
    height: 50px;
    line-height: 50px;
  }
  // Sales points section
  .sales-points .column {
    width: 100%;
  }
  .responsive-items a.logo img {
    height: 40px;
  }
  .box .responsive-items {
    padding-top: 0;
  }
  .responsive-menu-button {
    height: 40px;
    width: 40px;
    background-size: 20px;
  }
  // General
  .centered-headline {
    font-size: 30px;
    margin: 40px 0;
  }
  // Sales points
  .sales-points img {
    max-width: 80%;
  }
  // Testimonial section
  .testimonial-section {
    padding: 20px 20px 40px;
    height: 320px;
  }
  #frontpage-slider {
    display: flex;
  }
  #frontpage-slider .slide {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  #frontpage-slider p {
    font-size: 14px;
    padding: 0;
  }
  .bx-wrapper .bx-controls-direction a {
    display: none;
  }
  .bx-wrapper .bx-pager {
    display: block;
  }
  .bx-wrapper .bx-pager.bx-default-pager a {
    background: none;
    border: 2px solid #ffffff;
    height: 20px;
    width: 20px;
    border-radius: 50%;
  }
  .bx-wrapper .bx-pager.bx-default-pager a:hover {
    background: #ffffff;
  }
  .bx-wrapper .bx-pager.bx-default-pager a.active {
    background: #ffffff;
  }
  // Footer styles
  .main-footer .footer-columns {
    flex-direction: column;
  }
  // Subscription subpages
  .text-content {
    margin-top: 40px;
    margin-bottom: 20px;
  }
  .text-content p {
    font-size: 14px;
  }
  .step-indicator {
    height: auto;
    flex-direction: column;
    align-items: flex-start;
  }
  .button-group {
    flex-direction: column;
  }
  .button-group .button {
    margin-top: 20px;
  }
  .step-indicator p {
    padding: 0;
  }
  .step-indicator .stage .circle {
    margin-right: 0;
  }
  .form-container {
    padding: 20px;
  }
  .form-container p {
    padding: 0 20px;
  }
  .form-container.subscription-container form {
    padding: 0 20px;
  }
  .form-checkboxes .form-type-checkbox label {
    font-size: 14px;
  }
  .two-column-inputs {
    flex-direction: column;
  }
  .two-column-inputs .form-item {
    max-width: 100%;
    margin: 0;
    width: 100%;
  }
  // Content section on subpages
  .content-section .flex-container {
    flex-direction: column;
  }
  .content-section .main-content {
    margin-left: 0;
  }
  .subpage-sidebar {
    width: 100%;
  }
  .subpage-sidebar .sidebar-card {
    display: none;
  }
  .hidden-desktop {
    display: block;
  }
  .subpage-sidebar.hidden-desktop .sidebar-card,
  .contact-page .subpage-sidebar .sidebar-card {
    display: block;
  }
  .contact-page .subpage-sidebar {
    order: 2;
  }
  .contact-page.content-section .main-content {
    margin-bottom: 40px;
  }
  .contact-page .contact-form {
    max-width: 100%;
  }
  .contact-page .contact-form input {
    max-width: 100%;
  }
  .intro-section.subscriptions .text-content {
    margin: 20px 0;
  }
  // Hide the login button on mobile phones
  .responsive-navigation .menu .has-button,
  .has-button.responsive-version {
    display: none;
  }
}

@media screen and (max-width: 640px) {
  .has-button.responsive-version {
    display: none;
  }
  // Hide the login button on mobile phones
  //.responsive-navigation .has-button {
  //  display: none;
  //}
}

@media screen and (max-width: 400px) {
  .intro-section.subscriptions {
    height: 100%;
  }
  .intro-text h1 {
    font-size: 35px;
  }
  .intro-text h2 {
    font-size: 20px;
  }
  .button, input[type=submit].button {
    font-size: 14px;
  }
  .sales-points img {
    max-width: 60%;
  }
  // Subscription subpages
  .subscriptions .flex-container {
    justify-content: flex-start;
  }
  .text-content {
    margin-top: 20px;
  }
  .text-content h1 {
    font-size: 25px;
    margin-bottom: 20px;
  }
  .subscriptions .text-content ul li {
    font-size: 16px;
  }
  .subscriptions .text-content ul li:before {
    height: 10px;
    width: 10px;
  }
  .form-container {
    margin-bottom: 40px;
  }
  .intro-section.subpage .flex-container {
    height: calc(100% - 63px);
  }
  .intro-section.subpage .page-title .page__title {
    font-size: 30px;
    text-align: center;
  }
  .content-section .main-content {
    margin-bottom: 40px;
  }
  .sidebar-navigation {
    margin-bottom: 20px;
  }
  .sidebar-navigation li {
    height: 40px;
  }
  .sidebar-navigation li a {
    font-size: 14px;
  }
  .content-section .flex-container {
    margin-top: 20px;
  }
  .intro-section.subpage {
    height: 30vh;
  }
}

// This query is for smaller height screens
@media only screen and (max-height: 768px) {
  .form-container {
    padding: 20px;
    height: 60vh;
    min-height: 480px;
  }
  .form-container.subscription-container {
    min-height: 450px;
    height: inherit;
  }
  .form-container input[type=text],
  .form-container input[type=email],
  .form-container input[type=tel],
  .form-container input[type=password],
  .form-container textarea {
    height: 6.4vh;
    font-size: 16px;
    margin: 10px 0;
  }
  .form-container p {
    margin-bottom: 10px;
  }
  .form-container.subscription-container h2 {
    padding-top: 0;
  }
  .step-indicator {
    height: 9vh;
  }
  .step-indicator .stage {
    height: 9vh;
  }
  .tag-editor {
    margin-bottom: 10px;
  }
  .tag-editor .tag-editor-tag {
    height: 5.8vh;
    line-height: 5.8vh;
  }
  .tag-editor .tag-editor-delete {
    height: 5.8vh;
  }
  .tag-editor .tag-editor-delete i:before {
    line-height: 5.8vh;
  }
}

// This is to prevent the ipad from being broken because of the above query
@media only screen and (max-device-width: 1024px) {
  .form-container {
    height: auto;
    min-height: inherit;
  }
  .form-container.subscription-container {
    min-height: inherit;
    height: auto;
  }
  .form-container input[type=text],
  .form-container input[type=email],
  .form-container input[type=tel],
  .form-container input[type=password],
  .form-container textarea {
    height: 60px;
    font-size: 18px;
    margin: 10px 0;
  }
  .form-container p {
    margin-bottom: 10px;
  }
  .form-container.subscription-container h2 {
    padding-top: 20px;
  }
  .step-indicator {
    height: auto;
  }
  .step-indicator .stage {
    height: 60px;
  }
  .tag-editor {
    margin-bottom: 20px;
  }
  .tag-editor .tag-editor-tag {
    height: 40px;
    line-height: 40px;
  }
  .tag-editor .tag-editor-delete {
    height: 40px;
  }
  .tag-editor .tag-editor-delete i:before {
    line-height: 20px;
  }
}

@media screen and (max-height: 400px) {
  .intro-section {
    height: 100vh;
  }
  .intro-section.subpage {
    height: 55vh;
  }
  .intro-section.subpage .page-title .page__title {
    font-size: 35px;
  }
  .responsive-navigation .menu .menu li {
    height: 20px;
  }
  .responsive-navigation .menu li {
    height: 40px;
  }
  // Hide the login button on mobile phones
  //.responsive-navigation .has-button,
  //.has-button.responsive-version {
  //  display: none;
  //}
}

