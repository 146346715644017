// Override webform default styling
.form-item, .form-actions {
  margin: 0;
  padding: 0;
}

.webform-container-inline label {
  display: none;
}

input[type=text],
input[type=email],
input[type=tel],
input[type=password],
textarea {
  height: 60px;
  width: 100%;
  font-size: 18px;
  margin: 10px 0;
  border-radius: 10px;
  padding-left: 10px;
  -webkit-appearance: none;
  border: 1px solid $grey-color;
  color: $text-color;
  transition: $default-transition border;
  font-family: 'sofia-pro-soft', Helvetica, sans-serif;
  outline: none;
  &:focus {
    border: 1px solid darken($grey-color, 20%);
  }
}

textarea,
.form-textarea-wrapper textarea {
  height: auto;
  padding-top: 10px;
  margin: 10px 0 20px;
  font-size: 18px;
}

// Webform specific
.webform-container-inline div.description,
.element-invisible {
  display: none;
}

// Special styling for the tag editor (used in the subscription step form)
.tag-editor {
  padding: 10px 0;
  border-radius: 10px;
  border-color: $grey-color;
  margin-bottom: 20px;
  margin-top: 10px;
}

.tag-editor li {
  margin-left: 6px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px;
}

.tag-editor li.placeholder {
  box-shadow: none;
}

.tag-editor li:first-child {
  display: none;
}

.tag-editor li input {
  border: none !important;
  height: 40px;
  margin: 0;
}

.tag-editor div {
  padding: 10px;
}

.tag-editor .tag-editor-tag {
  background: $primary-color;
  color: #ffffff;
  font-size: 16px;
  border-radius: 10px 0 0 10px;
  padding: 0 5px 0 15px;
  height: 40px;
  line-height: 40px;
  overflow: hidden;
}

.tag-editor .tag-editor-spacer {
  display: none;
}

.tag-editor .tag-editor-delete {
  background: $primary-color;
  border-radius: 0 10px 10px 0;
  height: 40px;
  padding: 0 10px 0 5px;
  margin-left: -1px;
}

.tag-editor .tag-editor-delete i:before {
  color: #ffffff;
  font-size: 20px;
  line-height: 40px;
}

.tag-editor .tag-editor-delete:hover i:before {
  color: $tertiary-color;
}

// Fake checkboxes
input[type=checkbox] {
  visibility: hidden;
  position: absolute;
  left: -99999px;
}

.form-checkboxes .form-type-checkbox {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
}

.form-checkboxes:first-child {
  margin-bottom: 0;
}

.form-checkboxes .form-type-checkbox label {
  padding-left: 30px;
  position: relative;
  user-select: none;
  cursor: pointer;
}

.form-checkboxes .form-type-checkbox label:before {
  content: '';
  height: 20px;
  width: 20px;
  position: absolute;
  left: 0;
  top: 50%;
  border-radius: 6px;
  visibility: visible;
  border: 1px solid $grey-color;
  transform: translateY(-50%);
}

input[type=checkbox]:checked ~ label:before {
  background: $secondary-color url(../images/checkmark.svg) no-repeat center center;
  background-size: 12px;
  border: 1px solid $secondary-color;
}

input[placeholder] {
  color: darken($grey-color, 30%);
}

// Placeholder styling
input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  color: darken($grey-color, 30%);
}

input:-moz-placeholder, textarea:-moz-placeholder { /* Firefox 18- */
  color: darken($grey-color, 30%);
}

input::-moz-placeholder, textarea::-moz-placeholder {  /* Firefox 19+ */
  color: darken($grey-color, 30%);
}

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  color: darken($grey-color, 30%);
}

// New styles