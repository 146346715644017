.main-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
}

.main-navigation .links {
  display: flex;
  align-items: center;
  width: auto;
}

.main-navigation a {
  color: #ffffff;
  text-decoration: none;
  text-transform: uppercase;
}

.main-navigation a.logo {
  margin-left: 0;
}

// Drupal menu specific styling for level 2 side menu
.sidebar-navigation {
	.menu li.leaf {
	  list-style-type: none;
	  list-style-image: none;
	}
	
	ul.menu li {
	  margin: 0;
	}
	
	ul li.collapsed,
	ul li.expanded {
	  list-style-image: none;
	}
	
	.wrap {
	  display: flex;
	  align-items: center;
	}
}

.main-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px;
}

.main-navigation .links {
  display: flex;
  align-items: center;
  width: auto;
}

.main-navigation a {
  color: #ffffff;
  text-decoration: none;
  text-transform: uppercase;
}

.main-navigation a.logo {
  margin-left: 0;
}

// This button inherits styling from the global button
.main-navigation a.button {
  background-image: url(../images/lock.svg);
  background-repeat: no-repeat;
  background-position: 170px center;
  width: 220px;
  text-align: center;
  padding: 0;
  padding-right: 35px;
}

.main-navigation .menu-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.main-navigation .menu {
  padding-left: 0;
  margin: 0;
  display: flex;
  align-items: center;
  width: auto;
}

.main-navigation .menu li {
  list-style-type: none;
  margin-left: 40px;
}

.main-navigation .menu li:last-child {
  margin-right: 40px;
}

.main-navigation .menu li.active {
  position: relative;
  &.active:after {
    content: '';
    height: 2px;
    width: 60%;
    background: #ffffff;
    position: absolute;
    top: 25px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.main-navigation .menu li.active ul.menu li.active:after {
  display: none;
}

.main-navigation .menu li.active ul.menu li.active a:before {
  background: #ffffff;
}

.main-navigation .menu li.expanded {
  position: relative;
}

.main-navigation .menu li.expanded a,
.responsive-navigation .menu li.expanded a {
  margin-left: 0;
  position: relative;
  z-index: 3;
}

.main-navigation .menu li.expanded > a:before,
.responsive-navigation .menu li.expanded > a:before {
  content: '';
  height: 6px;
  width: 10px;
  position: absolute;
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
  background: url(../images/arrow_down.svg) no-repeat center center;
}

.main-navigation .menu .menu {
  position: absolute;
  opacity: 0;
  min-width: 180px;
  padding: 10px 0;
  border-radius: 10px;
  right: -30px;
  top: -10px;
  padding-left: 0;
  background: rgba(255, 255, 255, .2);
  transition: $default-transition opacity;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: inherit;
}

.main-navigation .menu .menu li {
  margin-right: 0;
}

.main-navigation .menu .menu:before {
  content: '';
  height: 2px;
  width: 100%;
  position: absolute;
  background: rgba(255, 255, 255, .1);
  left: 0;
  right: 0;
  top: 35px;
}

.main-navigation .menu .menu li {
  padding-left: 30px;
  padding-bottom: 10px;
  margin-left: 0;
}

.main-navigation .menu .menu li a {
  position: relative;
}

.main-navigation .menu .menu li a:before {
  content: '';
  height: 4px;
  width: 4px;
  border: 1px solid #ffffff;
  border-radius: 50%;
  position: absolute;
  background-color: transparent;
  top: 50%;
  left: -15px;
  transform: translate3d(0, -50%, 0);
  transition: background $default-transition;
}

.main-navigation .menu .menu li:hover a:before {
  background-color: #ffffff;
}

.main-navigation .menu .menu li:first-child {
  padding-top: 35px;
}

.main-navigation .menu .menu li:last-child {
  padding-bottom: 0;
}

.main-navigation .menu .menu li a {
  text-transform: none;
  display: block;
}

.main-navigation .menu li.expanded:hover .menu {
  visibility: visible;
  opacity: 1;
}

// Responsive menu styles
.responsive-menu-button {
  display: none;
  height: 60px;
  width: 60px;
  border: none;
  background: transparent url(../images/responsive-menu.svg) no-repeat center center;
  background-size: 40px;
  cursor: pointer;
  &:active, &:focus {
    outline: none;
  }
}

.has-button.responsive-version {
  display: none;
  margin-right: 20px;
}

.has-button.responsive-version a.button {
  margin: 0;
}

.responsive-items {
  align-items: center;
  display: none;
}

.close-responsive-menu-button {
  display: none;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: none;
  -webkit-appearance: none;
  background: $tertiary-color url(../images/close-icon.svg) no-repeat center center;
  background-size: 20px;
  cursor: pointer;
  &:focus, &:active {
    outline: none;
  }
}

.modal-overlay {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, .5);
  z-index: 3;
}

.responsive-navigation {
  display: none;
}

