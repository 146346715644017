.contact-page .sidebar-card {
  text-align: left;
}

.contact-page .sidebar-card p {
  margin-bottom: 0;
}

.contact-page .link-group {
  margin-bottom: 10px;
}

.contact-page .link-group a {
  font-size: 25px;
  color: $secondary-color;
}

.contact-page .contact-form {
  max-width: 600px;
}

.contact-page .contact-form .intro-text {
  text-align: left;
  display: block;
}

.contact-page .contact-form .intro-text h2 {
  display: block;
  margin-top: 0;
  text-align: left;
  font-family: inherit;
}

.contact-page .contact-form .error-text {
  color: $tertiary-color;
  font-weight: bold;
}

.contact-page .contact-form form {
margin-top: 20px;
}

.contact-page .contact-form input {
  max-width: 400px;
}

.contact-page input[type=submit] {
  float: right;
}

.contact-page .address-box {
  border-top: 1px solid $grey-color;
  padding-top: 20px;
}

.contact-page .address-box address {
  line-height: 1.5;
}

.contact-page .address-box a {
  display: block;
  margin-top: 20px;
}