// User page specific
.page-user .form-actions {
  margin: 20px 0;
}

.page-user .content-section .main-content {
  margin-left: 0;
}

.page-user .content-section .main-content h2 {
  font-size: 30px;
}

.page-user .content-section .main-content .description {
  margin-bottom: 20px;
}

.page-user .intro-section.subpage {
  height: 25vh;
}