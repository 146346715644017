// Subpage container
.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
}

body.front .container {
  height: calc(100% - 83px);
}

.centered-headline {
  font-size: 40px;
  text-align: center;
  margin: 60px 0;
}

.muted {
  opacity: .4;
}

.intro-section {
  height: 80vh;
  width: 100%;
  background: url(../images/header-image-frontpage.jpg) no-repeat center center;
  background-size: cover;
  color: #ffffff;
  &.subscriptions {
    background-image: url(../images/header-image-subpage.jpg);
    height: 100vh;
	min-height: 700px;
  }
  &.subpage {
    background-image: url(../images/intro-section.jpg);
    height: 45vh;
  }
}
@at-root body.page-node-228398 .intro-section.subscriptions {
  background-image: url(../images/header-image-subpage-proeveperiode.jpg);
  background-position: center bottom;
  background-size: 100% auto;
  background-color: #9f2b2e;

  .text-content ul li:before {
    background-color: #ffb600;
  }
}

#autologinframe {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px; width: 1px;
  margin: -1px; padding: 0; border: 0;
}

.spinner {
  background-color: #4c7aa6;
  border-radius: 4px;
  width: 40px;
  height: 40px;
  margin: 60px auto;
  animation: sk-rotateplane 1.2s infinite ease-in-out;
}

.fullmodal {
  background: rgba(#DC6F2E, 0.9);
  //visibility: hidden;
  //opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  transform: perspective(1000px) rotateX(90deg);
  transition: /* opacity 0.25s cubic-bezier(0.42, 0, 0.58, 1), */
              transform 0.25s cubic-bezier(0.42, 0, 0.58, 1);
  backface-visibility: hidden;
  transform-style: preserve-3d;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  text-align: center;


  &.open {
    //visibility: visible;
    //opacity: 1;
    transform: perspective(1000px) rotateX(0deg);
  }

  .close {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border: none;
    background: #fff url(../images/close-icon-orange.svg) no-repeat center center;
    background-size: 20px;
    cursor: pointer;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  }

  .content {
    color: #fff;
  }

  h1 {
    font-size: 30px;
    padding-bottom: 40px;
  }

  p {
    font-size: 20px;
  }
}

@-webkit-keyframes sk-rotateplane {
  0% { -webkit-transform: perspective(120px) }
  50% { -webkit-transform: perspective(120px) rotateY(180deg) }
  100% { -webkit-transform: perspective(120px) rotateY(180deg)  rotateX(180deg) }
}

@keyframes sk-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg)
  } 50% {
      transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
      -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg)
    } 100% {
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
        -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
      }
}

