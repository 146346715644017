@charset "UTF-8";
/* surrounding tag container */
.tag-editor {
  list-style-type: none;
  padding: 0 5px 0 0;
  margin: 0;
  overflow: hidden;
  border: 1px solid #eee;
  cursor: text;
  font: normal 14px sans-serif;
  color: #555;
  background: #fff; }

/* core styles usually need no change */
.tag-editor li {
  display: block;
  float: left;
  overflow: hidden;
  margin: 3px 0;
  line-height: 1.5; }

.tag-editor div {
  float: left;
  padding: 0 4px; }

.tag-editor .placeholder {
  padding: 0 8px;
  color: #bbb; }

.tag-editor .tag-editor-spacer {
  padding: 0;
  width: 8px;
  overflow: hidden;
  color: transparent;
  background: none; }

.tag-editor input {
  vertical-align: inherit;
  border: 0;
  outline: none;
  padding: 0;
  margin: 0;
  cursor: text;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  font-style: inherit;
  box-shadow: none;
  background: none; }

/* hide original input field or textarea visually to allow tab navigation */
.tag-editor-hidden-src {
  position: absolute !important;
  left: -99999px; }

/* hide IE10 "clear field" X */
.tag-editor ::-ms-clear {
  display: none; }

/* tag style */
.tag-editor .tag-editor-tag {
  padding-left: 5px;
  color: #46799b;
  background: #e0eaf1;
  white-space: nowrap;
  overflow: hidden;
  cursor: pointer;
  border-radius: 2px 0 0 2px; }

/* delete icon */
.tag-editor .tag-editor-delete {
  background: #e0eaf1;
  cursor: pointer;
  border-radius: 0 2px 2px 0;
  padding-left: 3px;
  padding-right: 4px; }

.tag-editor .tag-editor-delete i:before {
  display: inline-block;
  line-height: 20px;
  font-size: 16px;
  color: #8ba7ba;
  content: "×"; }

.tag-editor .tag-editor-delete:hover i:before {
  color: #d65454; }

.tag-editor .tag-editor-tag.active + .tag-editor-delete, .tag-editor .tag-editor-tag.active + .tag-editor-delete i {
  visibility: hidden;
  cursor: text; }

.tag-editor .tag-editor-tag.active {
  background: none !important; }

/* jQuery UI autocomplete - code.jquery.com/ui/1.10.2/themes/smoothness/jquery-ui.css */
.ui-autocomplete {
  position: absolute;
  top: 0;
  left: 0;
  cursor: default;
  font-size: 14px; }

.ui-front {
  z-index: 9999; }

.ui-menu {
  list-style: none;
  padding: 1px;
  margin: 0;
  display: block;
  outline: none; }

.ui-menu .ui-menu-item a {
  text-decoration: none;
  display: block;
  padding: 2px .4em;
  line-height: 1.4;
  min-height: 0;
  /* support: IE7 */ }

.ui-widget-content {
  border: 1px solid #bbb;
  background: #fff;
  color: #555; }

.ui-widget-content a {
  color: #46799b; }

.ui-widget-content .ui-state-hover, .ui-widget-header .ui-state-hover, .ui-state-focus, .ui-widget-content .ui-state-focus, .ui-widget-header .ui-state-focus {
  background: #e0eaf1; }

.ui-helper-hidden-accessible {
  display: none; }

/**
 * BxSlider v4.1.2 - Fully loaded, responsive content slider
 * http://bxslider.com
 *
 * Written by: Steven Wanderski, 2014
 * http://stevenwanderski.com
 * (while drinking Belgian ales and listening to jazz)
 *
 * CEO and founder of bxCreative, LTD
 * http://bxcreative.com
 */
/** RESET AND LAYOUT
===================================*/
.bx-wrapper {
  position: relative;
  margin: 0 auto 60px;
  padding: 0;
  *zoom: 1; }

.bx-wrapper img {
  max-width: 100%;
  display: block; }

/** THEME
===================================*/
.bx-wrapper .bx-viewport {
  -moz-box-shadow: 0 0 5px #ccc;
  -webkit-box-shadow: 0 0 5px #ccc;
  box-shadow: 0 0 5px #ccc;
  border: 5px solid #fff;
  left: -5px;
  background: #fff;
  /*fix other elements on the page moving (on Chrome)*/
  -webkit-transform: translatez(0);
  -moz-transform: translatez(0);
  -ms-transform: translatez(0);
  -o-transform: translatez(0);
  transform: translatez(0); }

.bx-wrapper .bx-pager,
.bx-wrapper .bx-controls-auto {
  position: absolute;
  bottom: -30px;
  width: 100%; }

/* LOADER */
.bx-wrapper .bx-loading {
  min-height: 50px;
  background: url(../images/bx_loader.gif) center center no-repeat #fff;
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 2000; }

/* PAGER */
.bx-wrapper .bx-pager {
  text-align: center;
  font-size: .85em;
  font-family: Arial;
  font-weight: bold;
  color: #666;
  padding-top: 20px; }

.bx-wrapper .bx-pager .bx-pager-item,
.bx-wrapper .bx-controls-auto .bx-controls-auto-item {
  display: inline-block;
  *zoom: 1;
  *display: inline; }

.bx-wrapper .bx-pager.bx-default-pager a {
  background: #666;
  text-indent: -9999px;
  display: block;
  width: 10px;
  height: 10px;
  margin: 0 5px;
  outline: 0;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px; }

.bx-wrapper .bx-pager.bx-default-pager a:hover,
.bx-wrapper .bx-pager.bx-default-pager a.active {
  background: #000; }

/* DIRECTION CONTROLS (NEXT / PREV) */
.bx-wrapper .bx-prev {
  left: 10px;
  background: url(images/controls.png) no-repeat 0 -32px; }

.bx-wrapper .bx-next {
  right: 10px;
  background: url(images/controls.png) no-repeat -43px -32px; }

.bx-wrapper .bx-prev:hover {
  background-position: 0 0; }

.bx-wrapper .bx-next:hover {
  background-position: -43px 0; }

.bx-wrapper .bx-controls-direction a {
  position: absolute;
  top: 50%;
  margin-top: -16px;
  outline: 0;
  width: 32px;
  height: 32px;
  text-indent: -9999px;
  z-index: 9999; }

.bx-wrapper .bx-controls-direction a.disabled {
  display: none; }

/* AUTO CONTROLS (START / STOP) */
.bx-wrapper .bx-controls-auto {
  text-align: center; }

.bx-wrapper .bx-controls-auto .bx-start {
  display: block;
  text-indent: -9999px;
  width: 10px;
  height: 11px;
  outline: 0;
  background: url(images/controls.png) -86px -11px no-repeat;
  margin: 0 3px; }

.bx-wrapper .bx-controls-auto .bx-start:hover,
.bx-wrapper .bx-controls-auto .bx-start.active {
  background-position: -86px 0; }

.bx-wrapper .bx-controls-auto .bx-stop {
  display: block;
  text-indent: -9999px;
  width: 9px;
  height: 11px;
  outline: 0;
  background: url(images/controls.png) -86px -44px no-repeat;
  margin: 0 3px; }

.bx-wrapper .bx-controls-auto .bx-stop:hover,
.bx-wrapper .bx-controls-auto .bx-stop.active {
  background-position: -86px -33px; }

/* PAGER WITH AUTO-CONTROLS HYBRID LAYOUT */
.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-pager {
  text-align: left;
  width: 80%; }

.bx-wrapper .bx-controls.bx-has-controls-auto.bx-has-pager .bx-controls-auto {
  right: 0;
  width: 35px; }

/* IMAGE CAPTIONS */
.bx-wrapper .bx-caption {
  position: absolute;
  bottom: 0;
  left: 0;
  background: #666\9;
  background: rgba(80, 80, 80, 0.75);
  width: 100%; }

.bx-wrapper .bx-caption span {
  color: #fff;
  font-family: Arial;
  display: block;
  font-size: .85em;
  padding: 10px; }

/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html, body, div, span, applet, object, iframe, h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, acronym, address, big, cite, code, del, dfn, em, img, ins, kbd, q, s, samp, small, strike, strong, sub, sup, tt, var, b, u, i, center, dl, dt, dd, ol, ul, li, fieldset, form, label, legend, table, caption, tbody, tfoot, thead, tr, th, td, article, aside, canvas, details, embed, figure, figcaption, footer, header, hgroup, menu, nav, output, ruby, section, summary, time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font: inherit;
  vertical-align: baseline; }

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after {
  content: '';
  content: none; }

q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

* {
  box-sizing: border-box; }

@font-face {
  font-family: "sofia-pro-soft";
  src: url("../fonts/sofia-pro-soft-light.woff2") format("woff2"), url("../fonts/sofia-pro-soft-light.woff") format("woff"), url("../fonts/sofia-pro-soft-light.otf") format("opentype");
  font-style: normal;
  font-display: block; }

@font-face {
  font-family: 'Karlo Sans Regular';
  src: url("../fonts/karlosans_regular.eot");
  src: url("../fonts/karlosans_regular.woff") format("woff"); }

@font-face {
  font-family: 'Karlo Sans Light Italic';
  src: url("../fonts/karlosans_italic.eot");
  src: url("../fonts/karlosans_italic.woff") format("woff"); }

@font-face {
  font-family: 'Karlo Sans Bold';
  src: url("../fonts/karlosans_bold.eot");
  src: url("../fonts/karlosans_bold.woff") format("woff"); }

body {
  font-family: 'sofia-pro-soft', Helvetica, sans-serif;
  letter-spacing: 0.05em;
  color: #262626;
  position: relative; }

body.menu-open {
  overflow: hidden; }

p {
  font-family: 'Karlo Sans Regular', 'Helvetica Neue Light', Helvetica, Arial, sans-serif;
  line-height: 1.5; }

.main-content p {
  margin-bottom: 20px; }

a {
  text-decoration: none;
  color: #4c7aa6; }

i, em {
  font-family: 'Karlo Sans Light Italic'; }

strong, b {
  font-family: 'Karlo Sans Bold'; }

small {
  font-size: 70%; }

ul {
  padding-left: 40px; }

ul li {
  list-style-type: circle; }

.main-content ul {
  padding-left: 40px;
  margin: 0 0 20px; }

.main-content ul li {
  list-style-type: circle;
  margin-bottom: 5px;
  line-height: 1.5;
  font-family: 'Karlo Sans Regular', 'Helvetica Neue Light', Helvetica, Arial, sans-serif; }

.main-content ol {
  margin: 0 0 20px; }

.main-content ol li {
  margin-bottom: 5px;
  font-family: 'Karlo Sans Regular', 'Helvetica Neue Light', Helvetica, Arial, sans-serif;
  line-height: 1.5; }

.main-content ul li:last-child {
  margin-bottom: 0; }

.main-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px; }

.main-navigation .links {
  display: flex;
  align-items: center;
  width: auto; }

.main-navigation a {
  color: #ffffff;
  text-decoration: none;
  text-transform: uppercase; }

.main-navigation a.logo {
  margin-left: 0; }

.sidebar-navigation .menu li.leaf {
  list-style-type: none;
  list-style-image: none; }

.sidebar-navigation ul.menu li {
  margin: 0; }

.sidebar-navigation ul li.collapsed,
.sidebar-navigation ul li.expanded {
  list-style-image: none; }

.sidebar-navigation .wrap {
  display: flex;
  align-items: center; }

.main-navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 20px; }

.main-navigation .links {
  display: flex;
  align-items: center;
  width: auto; }

.main-navigation a {
  color: #ffffff;
  text-decoration: none;
  text-transform: uppercase; }

.main-navigation a.logo {
  margin-left: 0; }

.main-navigation a.button {
  background-image: url(../images/lock.svg);
  background-repeat: no-repeat;
  background-position: 170px center;
  width: 220px;
  text-align: center;
  padding: 0;
  padding-right: 35px; }

.main-navigation .menu-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center; }

.main-navigation .menu {
  padding-left: 0;
  margin: 0;
  display: flex;
  align-items: center;
  width: auto; }

.main-navigation .menu li {
  list-style-type: none;
  margin-left: 40px; }

.main-navigation .menu li:last-child {
  margin-right: 40px; }

.main-navigation .menu li.active {
  position: relative; }
  .main-navigation .menu li.active.active:after {
    content: '';
    height: 2px;
    width: 60%;
    background: #ffffff;
    position: absolute;
    top: 25px;
    left: 50%;
    transform: translateX(-50%); }

.main-navigation .menu li.active ul.menu li.active:after {
  display: none; }

.main-navigation .menu li.active ul.menu li.active a:before {
  background: #ffffff; }

.main-navigation .menu li.expanded {
  position: relative; }

.main-navigation .menu li.expanded a,
.responsive-navigation .menu li.expanded a {
  margin-left: 0;
  position: relative;
  z-index: 3; }

.main-navigation .menu li.expanded > a:before,
.responsive-navigation .menu li.expanded > a:before {
  content: '';
  height: 6px;
  width: 10px;
  position: absolute;
  left: -20px;
  top: 50%;
  transform: translateY(-50%);
  background: url(../images/arrow_down.svg) no-repeat center center; }

.main-navigation .menu .menu {
  position: absolute;
  opacity: 0;
  min-width: 180px;
  padding: 10px 0;
  border-radius: 10px;
  right: -30px;
  top: -10px;
  padding-left: 0;
  background: rgba(255, 255, 255, 0.2);
  transition: 0.25s opacity;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: inherit; }

.main-navigation .menu .menu li {
  margin-right: 0; }

.main-navigation .menu .menu:before {
  content: '';
  height: 2px;
  width: 100%;
  position: absolute;
  background: rgba(255, 255, 255, 0.1);
  left: 0;
  right: 0;
  top: 35px; }

.main-navigation .menu .menu li {
  padding-left: 30px;
  padding-bottom: 10px;
  margin-left: 0; }

.main-navigation .menu .menu li a {
  position: relative; }

.main-navigation .menu .menu li a:before {
  content: '';
  height: 4px;
  width: 4px;
  border: 1px solid #ffffff;
  border-radius: 50%;
  position: absolute;
  background-color: transparent;
  top: 50%;
  left: -15px;
  transform: translate3d(0, -50%, 0);
  transition: background 0.25s; }

.main-navigation .menu .menu li:hover a:before {
  background-color: #ffffff; }

.main-navigation .menu .menu li:first-child {
  padding-top: 35px; }

.main-navigation .menu .menu li:last-child {
  padding-bottom: 0; }

.main-navigation .menu .menu li a {
  text-transform: none;
  display: block; }

.main-navigation .menu li.expanded:hover .menu {
  visibility: visible;
  opacity: 1; }

.responsive-menu-button {
  display: none;
  height: 60px;
  width: 60px;
  border: none;
  background: transparent url(../images/responsive-menu.svg) no-repeat center center;
  background-size: 40px;
  cursor: pointer; }
  .responsive-menu-button:active, .responsive-menu-button:focus {
    outline: none; }

.has-button.responsive-version {
  display: none;
  margin-right: 20px; }

.has-button.responsive-version a.button {
  margin: 0; }

.responsive-items {
  align-items: center;
  display: none; }

.close-responsive-menu-button {
  display: none;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  border: none;
  -webkit-appearance: none;
  background: #e36e39 url(../images/close-icon.svg) no-repeat center center;
  background-size: 20px;
  cursor: pointer; }
  .close-responsive-menu-button:focus, .close-responsive-menu-button:active {
    outline: none; }

.modal-overlay {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 3; }

.responsive-navigation {
  display: none; }

.container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto; }

body.front .container {
  height: calc(100% - 83px); }

.centered-headline {
  font-size: 40px;
  text-align: center;
  margin: 60px 0; }

.muted {
  opacity: .4; }

.intro-section {
  height: 80vh;
  width: 100%;
  background: url(../images/header-image-frontpage.jpg) no-repeat center center;
  background-size: cover;
  color: #ffffff; }
  .intro-section.subscriptions {
    background-image: url(../images/header-image-subpage.jpg);
    height: 100vh;
    min-height: 700px; }
  .intro-section.subpage {
    background-image: url(../images/intro-section.jpg);
    height: 45vh; }

body.page-node-228398 .intro-section.subscriptions {
  background-image: url(../images/header-image-subpage-proeveperiode.jpg);
  background-position: center bottom;
  background-size: 100% auto;
  background-color: #9f2b2e; }
  body.page-node-228398 .intro-section.subscriptions .text-content ul li:before {
    background-color: #ffb600; }

#autologinframe {
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0; }

.spinner {
  background-color: #4c7aa6;
  border-radius: 4px;
  width: 40px;
  height: 40px;
  margin: 60px auto;
  animation: sk-rotateplane 1.2s infinite ease-in-out; }

.fullmodal {
  background: rgba(220, 111, 46, 0.9);
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  transform: perspective(1000px) rotateX(90deg);
  transition: transform 0.25s cubic-bezier(0.42, 0, 0.58, 1);
  backface-visibility: hidden;
  transform-style: preserve-3d;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 50px;
  text-align: center; }
  .fullmodal.open {
    transform: perspective(1000px) rotateX(0deg); }
  .fullmodal .close {
    display: block;
    position: absolute;
    top: 20px;
    right: 20px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    border: none;
    background: #fff url(../images/close-icon-orange.svg) no-repeat center center;
    background-size: 20px;
    cursor: pointer;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25); }
  .fullmodal .content {
    color: #fff; }
  .fullmodal h1 {
    font-size: 30px;
    padding-bottom: 40px; }
  .fullmodal p {
    font-size: 20px; }

@-webkit-keyframes sk-rotateplane {
  0% {
    -webkit-transform: perspective(120px); }
  50% {
    -webkit-transform: perspective(120px) rotateY(180deg); }
  100% {
    -webkit-transform: perspective(120px) rotateY(180deg) rotateX(180deg); } }

@keyframes sk-rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg); }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg); }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg); } }

.button, input[type=submit].button, input[type=submit] {
  height: 60px;
  min-width: 220px;
  line-height: 60px;
  -webkit-appearance: none;
  display: block;
  text-decoration: none;
  text-transform: uppercase;
  color: #ffffff;
  border: none;
  font-size: 16px;
  border-radius: 10px;
  text-align: center;
  padding: 0;
  cursor: pointer;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  transition: 0.25s background;
  font-family: 'sofia-pro-soft', Helvetica, sans-serif; }
  .button.orange, input[type=submit].button.orange, input[type=submit].orange {
    background-color: #e36e39; }
    .button.orange:hover, input[type=submit].button.orange:hover, input[type=submit].orange:hover {
      background-color: #e05e23; }
  .button.hollow, input[type=submit].button.hollow, input[type=submit].hollow {
    border: 2px solid #ffffff;
    line-height: 58px;
    transition: 0.25s all; }
    .button.hollow:hover, input[type=submit].button.hollow:hover, input[type=submit].hollow:hover {
      background-color: #ffffff;
      color: #262626; }
    .button.hollow.hollow-black, input[type=submit].button.hollow.hollow-black, input[type=submit].hollow.hollow-black {
      border: 2px solid #262626;
      color: #262626; }
  .button.green, input[type=submit].button.green, input[type=submit].green {
    background-color: #47ad86; }
    .button.green:hover, input[type=submit].button.green:hover, input[type=submit].green:hover {
      background-color: #409b78; }
  .button.has-icon, input[type=submit].button.has-icon, input[type=submit].has-icon {
    padding-right: 30px;
    background-image: url(../images/arrow-forward.svg);
    background-repeat: no-repeat;
    background-position: 150px center; }
  .button:focus, .button:active, input[type=submit].button:focus, input[type=submit].button:active, input[type=submit]:focus, input[type=submit]:active {
    outline: none; }

.link {
  font-size: inherit;
  color: #4c7aa6;
  text-decoration: none;
  margin-top: 20px;
  display: block; }
  .link:visited, .link:active, .link:focus {
    color: #4c7aa6; }

.main-footer {
  padding: 20px 0;
  background: #4c7aa6;
  color: #ffffff; }

.main-footer a {
  color: #ffffff; }

.main-footer .footer-columns {
  display: flex;
  justify-content: space-between; }

.main-footer .footer-columns .column h2 {
  font-size: 25px;
  font-family: 'sofia-pro-soft', Helvetica, sans-serif;
  padding-top: 20px;
  margin-bottom: 20px; }

.main-footer .footer-columns .column ul {
  padding-left: 0; }

.main-footer .footer-columns .column ul li {
  margin-bottom: 5px;
  list-style-type: none; }

.main-footer .footer-columns .column .muted {
  margin-top: 20px; }

.main-footer address p,
.main-footer address a {
  font-family: 'Karlo Sans Regular', 'Helvetica Neue Light', Helvetica, Arial, sans-serif;
  font-size: 16px;
  line-height: 1.4; }

.main-footer address a:first-child {
  display: block;
  margin-top: 20px; }

.main-footer img {
  height: 40px;
  margin-right: 10px; }

.form-item, .form-actions {
  margin: 0;
  padding: 0; }

.webform-container-inline label {
  display: none; }

input[type=text],
input[type=email],
input[type=tel],
input[type=password],
textarea {
  height: 60px;
  width: 100%;
  font-size: 18px;
  margin: 10px 0;
  border-radius: 10px;
  padding-left: 10px;
  -webkit-appearance: none;
  border: 1px solid #e1e1e1;
  color: #262626;
  transition: 0.25s border;
  font-family: 'sofia-pro-soft', Helvetica, sans-serif;
  outline: none; }
  input[type=text]:focus,
  input[type=email]:focus,
  input[type=tel]:focus,
  input[type=password]:focus,
  textarea:focus {
    border: 1px solid #aeaeae; }

textarea,
.form-textarea-wrapper textarea {
  height: auto;
  padding-top: 10px;
  margin: 10px 0 20px;
  font-size: 18px; }

.webform-container-inline div.description,
.element-invisible {
  display: none; }

.tag-editor {
  padding: 10px 0;
  border-radius: 10px;
  border-color: #e1e1e1;
  margin-bottom: 20px;
  margin-top: 10px; }

.tag-editor li {
  margin-left: 6px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  border-radius: 10px; }

.tag-editor li.placeholder {
  box-shadow: none; }

.tag-editor li:first-child {
  display: none; }

.tag-editor li input {
  border: none !important;
  height: 40px;
  margin: 0; }

.tag-editor div {
  padding: 10px; }

.tag-editor .tag-editor-tag {
  background: #4c7aa6;
  color: #ffffff;
  font-size: 16px;
  border-radius: 10px 0 0 10px;
  padding: 0 5px 0 15px;
  height: 40px;
  line-height: 40px;
  overflow: hidden; }

.tag-editor .tag-editor-spacer {
  display: none; }

.tag-editor .tag-editor-delete {
  background: #4c7aa6;
  border-radius: 0 10px 10px 0;
  height: 40px;
  padding: 0 10px 0 5px;
  margin-left: -1px; }

.tag-editor .tag-editor-delete i:before {
  color: #ffffff;
  font-size: 20px;
  line-height: 40px; }

.tag-editor .tag-editor-delete:hover i:before {
  color: #e36e39; }

input[type=checkbox] {
  visibility: hidden;
  position: absolute;
  left: -99999px; }

.form-checkboxes .form-type-checkbox {
  margin-bottom: 20px;
  display: flex;
  align-items: center; }

.form-checkboxes:first-child {
  margin-bottom: 0; }

.form-checkboxes .form-type-checkbox label {
  padding-left: 30px;
  position: relative;
  user-select: none;
  cursor: pointer; }

.form-checkboxes .form-type-checkbox label:before {
  content: '';
  height: 20px;
  width: 20px;
  position: absolute;
  left: 0;
  top: 50%;
  border-radius: 6px;
  visibility: visible;
  border: 1px solid #e1e1e1;
  transform: translateY(-50%); }

input[type=checkbox]:checked ~ label:before {
  background: #47ad86 url(../images/checkmark.svg) no-repeat center center;
  background-size: 12px;
  border: 1px solid #47ad86; }

input[placeholder] {
  color: #959595; }

input::-webkit-input-placeholder, textarea::-webkit-input-placeholder {
  color: #959595; }

input:-moz-placeholder, textarea:-moz-placeholder {
  /* Firefox 18- */
  color: #959595; }

input::-moz-placeholder, textarea::-moz-placeholder {
  /* Firefox 19+ */
  color: #959595; }

input:-ms-input-placeholder, textarea:-ms-input-placeholder {
  color: #959595; }

.intro-text {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.intro-text h1,
.intro-text h2 {
  font-size: 60px;
  font-family: 'sofia-pro-soft', Helvetica, sans-serif; }

.intro-text h2 {
  font-size: 35px;
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center; }

.intro-text .circle {
  height: 8px;
  width: 8px;
  border-radius: 50%;
  background: #78d4b2;
  margin: 0 10px; }

.intro-text p {
  width: 100%; }

.button-section {
  margin-top: 40px;
  display: flex;
  position: relative; }

.button-section .button {
  margin: 0 10px; }

.button-section .tilskudsbadge {
  width: 120px;
  height: 140px;
  position: absolute;
  top: 50%;
  right: -120px;
  transform: translateY(-50%);
  background: url(../images/tilskudsbadge-bg.svg) no-repeat center center;
  background-size: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 20px; }

.button-section .tilskudsbadge p {
  font-size: 14px;
  text-align: left;
  text-transform: uppercase;
  padding-left: 6px; }

.sales-points {
  display: flex;
  justify-content: space-between;
  margin-bottom: 80px; }

.sales-points .column {
  text-align: center;
  max-width: 340px; }

.sales-points img {
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  height: 300px;
  width: 300px;
  border-radius: 50%; }

.sales-points h2 {
  font-size: 25px;
  margin-top: 20px; }

.sales-points p {
  margin-top: 20px; }

.testimonial-section {
  width: 100%;
  background: #47ad86;
  height: auto;
  padding: 40px 0; }

.testimonial-section .container {
  height: 100%; }

#frontpage-slider p {
  padding: 0 100px;
  text-align: center;
  max-width: 100%;
  font-size: 18px;
  line-height: 2;
  color: #ffffff; }

#frontpage-slider .slide p.testimonial:before {
  content: '';
  height: 29px;
  width: 40px;
  display: inline-block;
  margin-right: 20px;
  background: url(../images/quote-mark.svg) no-repeat center bottom;
  background-size: 100%; }

#frontpage-slider .author {
  margin-top: 20px;
  line-height: 1; }

#frontpage-slider .slide {
  min-height: 160px;
  max-width: 100%; }

body.front .testimonial-section .container {
  height: 100%;
  max-width: 1200px; }

.bx-wrapper .bx-viewport {
  background: none;
  border: none;
  box-shadow: none; }

.bx-wrapper {
  margin: 0; }

.bx-wrapper .bx-controls-direction a {
  width: 44px;
  height: 44px; }

.bx-wrapper .bx-prev,
.bx-wrapper .bx-next {
  left: 0;
  height: 44px;
  width: 44px;
  background-size: 44px;
  background: url(../images/prev-slider-icon.svg) no-repeat center center; }

.bx-wrapper .bx-next {
  background: url(../images/next-slider-icon.svg) no-repeat center center;
  right: 0;
  left: inherit; }

.bx-wrapper .bx-next:hover {
  background-position: inherit; }

.bx-wrapper .bx-pager {
  display: none; }

.cta-section {
  margin: 80px 0; }

.cta-section .container {
  display: flex; }

.image-container img {
  max-width: 100%;
  height: auto; }

.cta-section .text-box {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding-left: 100px;
  width: 50%;
  max-width: 50%; }

.cta-section .image-box {
  max-width: 50%; }

.cta-section .text-box h1 {
  font-size: 30px;
  margin-bottom: 20px; }

.cta-section .text-box .button {
  width: 220px;
  margin: 0 auto; }

.cta-section .contact-info {
  margin-top: 20px; }

.cta-section .contact-info a {
  font-size: 18px;
  color: #262626;
  font-family: 'Karlo Sans Regular'; }

.notice-box {
  display: none;
  justify-content: center;
  align-items: center;
  min-height: 60px;
  font-size: 16px;
  text-align: center;
  width: 100%;
  background: #e36e39;
  color: #ffffff;
  padding: 16px; }
  .notice-box.shown {
    display: flex; }
  .notice-box a {
    color: #ffffff;
    text-decoration: underline; }
  .notice-box.uni-c {
    display: flex; }

.subscriptions .flex-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: calc(100% - 83px); }

.text-content h1 {
  font-size: 50px;
  font-family: 'sofia-pro-soft', Helvetica, sans-serif;
  margin-bottom: 40px; }

.text-content p {
  font-size: 20px; }

.intro-section.trial-page .text-content {
  max-width: 50%; }

.subscriptions .text-content {
  max-width: 40%; }

.subscriptions .text-content ul li {
  font-size: 25px;
  font-family: 'Karlo Sans Regular', 'Helvetica Neue Light', Helvetica, Arial, sans-serif;
  margin-bottom: 10px;
  padding-left: 40px;
  position: relative;
  list-style-type: none; }

.subscriptions .text-content ul li:before {
  content: '';
  height: 20px;
  width: 20px;
  border-radius: 50%;
  background: #e36e39;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%); }

.subscriptions .text-content ul {
  padding-left: 0; }

.form-container {
  padding: 40px 60px;
  background: #ffffff;
  width: 500px;
  border-radius: 10px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
  margin-bottom: 32px; }

.form-container h2 {
  font-size: 30px;
  color: #47ad86;
  text-align: center; }

.form-container p {
  color: #262626;
  margin-bottom: 20px;
  margin-top: 5px;
  font-size: 14px;
  text-align: center;
  font-family: 'sofia-pro-soft', Helvetica, sans-serif; }

.form-container a {
  text-align: center; }

.form-container .button {
  margin: 10px auto; }

.form-container label.option {
  color: #262626; }
  .form-container label.option a {
    text-decoration: underline; }

.form-container.subscription-container {
  padding: 0;
  min-width: 640px;
  width: 500px;
  flex-shrink: 0;
  margin-left: 40px; }
  .form-container.subscription-container h2 {
    padding-top: 20px; }
  .form-container.subscription-container h3 {
    color: #262626;
    margin: 20px 0 10px;
    font-size: 20px; }
  .form-container.subscription-container form {
    padding: 0 40px; }
  .form-container.subscription-container form .form-item a {
    color: #4c7aa6; }

.form-container .step {
  display: none; }
  .form-container .step.active {
    display: block; }
  .form-container .step.step3 label:first-child {
    color: #262626;
    font-size: 18px;
    margin-bottom: 10px;
    margin-top: 20px; }
  .form-container .step.step3 textarea {
    margin-bottom: 20px; }

.step-indicator {
  height: 60px;
  width: 100%;
  background: #47ad86;
  padding: 0 40px;
  display: flex;
  justify-content: space-between; }

.step.step3 {
  margin-top: 20px; }

.step-indicator .stage {
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: .5;
  cursor: pointer; }
  .step-indicator .stage.active {
    opacity: 1; }

.step-indicator .stage .circle {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: #4c7aa6;
  line-height: 30px;
  text-align: center;
  margin-right: 10px;
  flex-shrink: 0;
  padding-left: 2px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25); }

.step-indicator .stage p {
  margin: 0;
  color: #ffffff;
  text-shadow: 0 1px 1px rgba(0, 0, 0, 0.5);
  font-family: 'sofia-pro-soft', Helvetica, sans-serif; }

.button-group {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-top: 1px solid #e1e1e1;
  padding: 20px 0;
  margin-top: 10px;
  margin-bottom: 20px; }
  .button-group .link {
    margin-top: 0; }
  .button-group .button {
    margin: 0; }
  .button-group .link-box {
    display: flex; }

.two-column-inputs {
  display: flex; }

.two-column-inputs .form-item {
  margin: 0 10px;
  max-width: 50%;
  width: 50%; }

.two-column-inputs .form-item:first-child {
  margin-left: 0; }

.two-column-inputs .form-item:last-child {
  margin-right: 0; }

.form-container .form-item input[type="checkbox"] {
  opacity: 0;
  visibility: inherit;
  left: auto; }

.intro-section.subpage .flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 100px); }

.intro-section.subpage .page-title .page__title {
  font-size: 65px;
  font-family: 'sofia-pro-soft', Helvetica, sans-serif; }

.content-section .flex-container {
  display: flex;
  margin-top: 40px; }

.content-section .main-content {
  margin-bottom: 80px;
  max-width: 100%;
  margin-left: 80px;
  flex: 1; }

.content-section .main-content h1,
.content-section .main-content h2,
.content-section .main-content h3,
.content-section .main-content h4,
.content-section .main-content h5,
.content-section .main-content h6 {
  margin-bottom: 10px;
  color: #4c7aa6; }

.content-section .main-content h1 {
  font-size: 26px; }

.content-section .main-content h2 {
  font-size: 24px; }

.content-section .main-content h3 {
  font-size: 22px; }

.content-section .main-content h4 {
  font-size: 20px; }

.content-section .main-content h5 {
  font-size: 18px; }

.content-section .main-content h6 {
  font-size: 16px; }

.content-section .main-content img {
  max-width: 100%;
  height: auto; }

.content-section .image-container {
  margin: 20px 0;
  display: flex;
  justify-content: center;
  flex-flow: row wrap; }

.content-section .image-container .image-box {
  margin: 0 20px;
  flex-shrink: 0; }

.content-section .image-container .image-box img {
  max-width: 100%;
  height: auto; }

.sidebar-navigation, .sidebar-card {
  background: #ffffff;
  border-radius: 10px;
  margin-bottom: 40px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25); }

.subpage-sidebar {
  width: 280px;
  flex-shrink: 0; }

.hidden-desktop {
  display: none; }

.sidebar-navigation ul {
  padding-left: 0; }

.sidebar-navigation li {
  list-style-type: none;
  height: 60px;
  border-bottom: 1px solid #ebebeb; }
  .sidebar-navigation li a {
    display: block;
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;
    padding-left: 20px; }
  .sidebar-navigation li:hover, .sidebar-navigation li.active {
    background: #4c7aa6; }
    .sidebar-navigation li:hover a, .sidebar-navigation li.active a {
      color: #ffffff; }

.sidebar-navigation li:first-child {
  border-radius: 10px 10px 0 0; }

.sidebar-navigation li:last-child {
  border-bottom: none;
  border-radius: 0 0 10px 10px; }

.sidebar-card {
  padding: 40px 20px;
  text-align: center; }

.sidebar-card h1,
.sidebar-card h2,
.sidebar-card h3,
.sidebar-card h4,
.sidebar-card h5,
.sidebar-card h6 {
  font-size: 20px;
  margin-bottom: 10px; }

.sidebar-card p {
  margin-bottom: 20px; }

.sidebar-card .button {
  margin-bottom: 20px; }

.sidebar-card .button:last-child {
  margin-bottom: 0; }

#messages--error p {
  margin-bottom: 5px;
  padding: 10px 20px;
  background: #e36e39;
  color: #ffffff; }

#messages--error em {
  font-family: 'Karlo Sans Regular', 'Helvetica Neue Light', Helvetica, Arial, sans-serif; }

.contact-page .sidebar-card {
  text-align: left; }

.contact-page .sidebar-card p {
  margin-bottom: 0; }

.contact-page .link-group {
  margin-bottom: 10px; }

.contact-page .link-group a {
  font-size: 25px;
  color: #47ad86; }

.contact-page .contact-form {
  max-width: 600px; }

.contact-page .contact-form .intro-text {
  text-align: left;
  display: block; }

.contact-page .contact-form .intro-text h2 {
  display: block;
  margin-top: 0;
  text-align: left;
  font-family: inherit; }

.contact-page .contact-form .error-text {
  color: #e36e39;
  font-weight: bold; }

.contact-page .contact-form form {
  margin-top: 20px; }

.contact-page .contact-form input {
  max-width: 400px; }

.contact-page input[type=submit] {
  float: right; }

.contact-page .address-box {
  border-top: 1px solid #e1e1e1;
  padding-top: 20px; }

.contact-page .address-box address {
  line-height: 1.5; }

.contact-page .address-box a {
  display: block;
  margin-top: 20px; }

.page-user .form-actions {
  margin: 20px 0; }

.page-user .content-section .main-content {
  margin-left: 0; }

.page-user .content-section .main-content h2 {
  font-size: 30px; }

.page-user .content-section .main-content .description {
  margin-bottom: 20px; }

.page-user .intro-section.subpage {
  height: 25vh; }

@media screen and (max-width: 1368px) {
  .form-container.subscription-container {
    padding: 0;
    min-width: inherit;
    margin-left: 10%;
    width: 50%; }
  .step-indicator {
    padding: 0 20px; }
  .form-container .link {
    font-size: 14px; } }

@media screen and (max-width: 1200px) {
  .responsive-navigation {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 4;
    width: 280px;
    background: white;
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
    transform: translate3d(100%, 0, 0);
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
    transition: transform 0.25s cubic-bezier(0.42, 0, 0.58, 1); }
    .responsive-navigation.menu-shown {
      transform: translate3d(0, 0, -1000px); }
  .modal-overlay {
    transition: opacity 0.25s cubic-bezier(0.42, 0, 0.58, 1);
    height: 200%;
    transform: translateZ(-1001px); }
    .modal-overlay.overlay-shown {
      visibility: visible;
      opacity: 1; }
  .main-navigation {
    display: none; }
  .responsive-navigation li {
    text-transform: uppercase; }
  .responsive-navigation .menu a {
    color: #262626; }
  .responsive-navigation .menu li {
    width: 100%;
    border-bottom: 1px solid #e1e1e1;
    margin-left: 0;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center; }
  .responsive-navigation .menu:first-child {
    padding-top: 60px;
    padding-left: 0; }
  .responsive-navigation .menu li.expanded {
    padding-top: 20px;
    border-top: 1px solid #e1e1e1;
    height: auto;
    display: block;
    text-align: center; }
  .responsive-navigation .menu li.expanded > a:before {
    background-image: url(../images/arrow-down-alt.svg); }
  .responsive-navigation .menu .menu {
    position: static;
    visibility: visible;
    opacity: 1;
    min-width: 100%;
    background: none;
    border-radius: 0;
    padding: 10px 0; }
  .responsive-navigation .menu .menu li:first-child {
    padding: 0; }
  .responsive-navigation .menu li:last-child {
    margin-right: 0; }
  .responsive-navigation .menu .menu li {
    padding-left: 0;
    list-style-type: none;
    font-size: 14px;
    border-bottom: 0;
    height: 40px;
    text-transform: none; }
  .responsive-navigation .has-button {
    padding: 20px;
    border-bottom: 1px solid #e1e1e1; }
  .responsive-navigation .has-button a.button {
    margin: 0;
    width: 100%; }
  .main .menu:before,
  .main .menu li a:before,
  .main .menu li.active:after {
    display: none; }
  .responsive-navigation .menu .has-button {
    height: 80px; }
  .responsive-navigation a.button {
    margin: 10px 0;
    color: #ffffff; }
  .responsive-items {
    padding-top: 20px;
    display: flex;
    justify-content: space-between;
    width: 100%; }
  .responsive-items .box {
    display: flex; }
  .responsive-menu-button {
    display: block; }
  .has-button.responsive-version {
    display: block; }
  .close-responsive-menu-button {
    display: block;
    position: absolute;
    top: 10px;
    left: 10px;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25); }
  .sales-points .column {
    text-align: center;
    max-width: 30%;
    width: 30%; }
  .sales-points img {
    height: auto;
    max-width: 100%; }
  .sales-points h2 {
    font-size: 20px; }
  .subscriptions .flex-container {
    flex-direction: column;
    justify-content: flex-start; }
  .intro-section.subscriptions {
    height: 100%; }
  .intro-section.subscriptions .text-content {
    max-width: 100%;
    margin: 40px 0; }
  .form-container.subscription-container {
    width: 80%;
    margin-bottom: 80px;
    margin-left: 0; }
  .container {
    padding: 0 80px; } }

@media screen and (max-width: 1024px) {
  body {
    -webkit-overflow-scrolling: touch; }
  p, .link,
  .main-content ol li,
  .main-content ul li {
    font-size: 14px; }
  .cta-section .text-box {
    padding-left: 40px; }
  .text-content h1 {
    font-size: 45px;
    margin-bottom: 20px; }
  input[type=text],
  input[type=email],
  input[type=tel] {
    height: 40px;
    font-size: 16px;
    margin: 5px 0; }
  textarea,
  .form-textarea-wrapper textarea {
    font-size: 16px; }
  .subscriptions .text-content ul li {
    font-size: 25px; }
  .step-indicator {
    margin-top: 20px;
    justify-content: space-around; }
  .form-container.subscription-container h2 {
    padding-top: 20px; }
  .form-container {
    padding: 20px 40px;
    width: 80%;
    margin-bottom: 65px; }
  .form-container p {
    margin-bottom: 0; }
  .intro-section.subpage {
    height: 35vh; }
  .content-section .main-content {
    margin-left: 40px; }
  .content-section .image-container {
    justify-content: flex-start;
    flex-flow: inherit; }
  .content-section .image-container .image-box {
    margin: 0;
    flex-shrink: 1; }
  .content-section .image-container .image-box:first-child {
    margin-right: 10px; }
  .content-section .image-container .image-box:last-child {
    margin-left: 10px; }
  .content-section .image-container .image-box img {
    height: auto !important; } }

@media screen and (max-width: 768px) {
  .container {
    padding: 0 40px; }
  .intro-section {
    height: 70vh; }
  .sales-points {
    flex-flow: row wrap;
    justify-content: center;
    margin-bottom: 40px; }
  .sales-points .column {
    width: 50%;
    max-width: none;
    margin-bottom: 40px; }
  .sales-points .column:last-child {
    margin-bottom: 0; }
  .testimonial-section .container {
    padding: 0; }
  .bx-wrapper .bx-prev {
    left: 20px !important; }
  .bx-wrapper .bx-next {
    right: 20px !important; }
  .cta-section {
    margin: 40px 0; }
  .cta-section .container {
    flex-direction: column; }
  .cta-section .container .image-box {
    order: 2; }
  .cta-section .text-box {
    padding-left: 0;
    width: 100%;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 40px; }
  .cta-section .image-box {
    max-width: 100%; }
  .cta-section .text-box h1 {
    font-size: 25px; }
  .subscriptions .flex-container {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center; }
  .text-content {
    max-width: 100%;
    margin-bottom: 40px; }
  .form-container {
    width: 100%;
    padding: 20px; }
  .form-container.subscription-container {
    width: 100%; }
  .step-indicator {
    justify-content: space-between;
    padding: 0 40px; }
  .intro-section.subpage {
    height: 25vh; }
  .subpage-sidebar {
    width: 220px; }
  .sidebar-card .button {
    min-width: 100%;
    font-size: 14px; }
  .sidebar-card {
    padding: 20px; }
  .contact-page .link-group a {
    font-size: 18px; }
  .contact-page .address-box address {
    font-size: 14px; } }

@media screen and (max-width: 767px) {
  .container {
    padding: 0 20px; }
  .intro-text h1 {
    font-size: 45px; }
  .intro-text h2 {
    font-size: 25px; }
  .button-section {
    flex-direction: column;
    margin-top: 20px; }
  .button-section .tilskudsbadge {
    display: none; }
  .button-section .button {
    margin: 0 0 10px;
    height: 50px;
    line-height: 50px; }
  .sales-points .column {
    width: 100%; }
  .responsive-items a.logo img {
    height: 40px; }
  .box .responsive-items {
    padding-top: 0; }
  .responsive-menu-button {
    height: 40px;
    width: 40px;
    background-size: 20px; }
  .centered-headline {
    font-size: 30px;
    margin: 40px 0; }
  .sales-points img {
    max-width: 80%; }
  .testimonial-section {
    padding: 20px 20px 40px;
    height: 320px; }
  #frontpage-slider {
    display: flex; }
  #frontpage-slider .slide {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column; }
  #frontpage-slider p {
    font-size: 14px;
    padding: 0; }
  .bx-wrapper .bx-controls-direction a {
    display: none; }
  .bx-wrapper .bx-pager {
    display: block; }
  .bx-wrapper .bx-pager.bx-default-pager a {
    background: none;
    border: 2px solid #ffffff;
    height: 20px;
    width: 20px;
    border-radius: 50%; }
  .bx-wrapper .bx-pager.bx-default-pager a:hover {
    background: #ffffff; }
  .bx-wrapper .bx-pager.bx-default-pager a.active {
    background: #ffffff; }
  .main-footer .footer-columns {
    flex-direction: column; }
  .text-content {
    margin-top: 40px;
    margin-bottom: 20px; }
  .text-content p {
    font-size: 14px; }
  .step-indicator {
    height: auto;
    flex-direction: column;
    align-items: flex-start; }
  .button-group {
    flex-direction: column; }
  .button-group .button {
    margin-top: 20px; }
  .step-indicator p {
    padding: 0; }
  .step-indicator .stage .circle {
    margin-right: 0; }
  .form-container {
    padding: 20px; }
  .form-container p {
    padding: 0 20px; }
  .form-container.subscription-container form {
    padding: 0 20px; }
  .form-checkboxes .form-type-checkbox label {
    font-size: 14px; }
  .two-column-inputs {
    flex-direction: column; }
  .two-column-inputs .form-item {
    max-width: 100%;
    margin: 0;
    width: 100%; }
  .content-section .flex-container {
    flex-direction: column; }
  .content-section .main-content {
    margin-left: 0; }
  .subpage-sidebar {
    width: 100%; }
  .subpage-sidebar .sidebar-card {
    display: none; }
  .hidden-desktop {
    display: block; }
  .subpage-sidebar.hidden-desktop .sidebar-card,
  .contact-page .subpage-sidebar .sidebar-card {
    display: block; }
  .contact-page .subpage-sidebar {
    order: 2; }
  .contact-page.content-section .main-content {
    margin-bottom: 40px; }
  .contact-page .contact-form {
    max-width: 100%; }
  .contact-page .contact-form input {
    max-width: 100%; }
  .intro-section.subscriptions .text-content {
    margin: 20px 0; }
  .responsive-navigation .menu .has-button,
  .has-button.responsive-version {
    display: none; } }

@media screen and (max-width: 640px) {
  .has-button.responsive-version {
    display: none; } }

@media screen and (max-width: 400px) {
  .intro-section.subscriptions {
    height: 100%; }
  .intro-text h1 {
    font-size: 35px; }
  .intro-text h2 {
    font-size: 20px; }
  .button, input[type=submit].button {
    font-size: 14px; }
  .sales-points img {
    max-width: 60%; }
  .subscriptions .flex-container {
    justify-content: flex-start; }
  .text-content {
    margin-top: 20px; }
  .text-content h1 {
    font-size: 25px;
    margin-bottom: 20px; }
  .subscriptions .text-content ul li {
    font-size: 16px; }
  .subscriptions .text-content ul li:before {
    height: 10px;
    width: 10px; }
  .form-container {
    margin-bottom: 40px; }
  .intro-section.subpage .flex-container {
    height: calc(100% - 63px); }
  .intro-section.subpage .page-title .page__title {
    font-size: 30px;
    text-align: center; }
  .content-section .main-content {
    margin-bottom: 40px; }
  .sidebar-navigation {
    margin-bottom: 20px; }
  .sidebar-navigation li {
    height: 40px; }
  .sidebar-navigation li a {
    font-size: 14px; }
  .content-section .flex-container {
    margin-top: 20px; }
  .intro-section.subpage {
    height: 30vh; } }

@media only screen and (max-height: 768px) {
  .form-container {
    padding: 20px;
    height: 60vh;
    min-height: 480px; }
  .form-container.subscription-container {
    min-height: 450px;
    height: inherit; }
  .form-container input[type=text],
  .form-container input[type=email],
  .form-container input[type=tel],
  .form-container input[type=password],
  .form-container textarea {
    height: 6.4vh;
    font-size: 16px;
    margin: 10px 0; }
  .form-container p {
    margin-bottom: 10px; }
  .form-container.subscription-container h2 {
    padding-top: 0; }
  .step-indicator {
    height: 9vh; }
  .step-indicator .stage {
    height: 9vh; }
  .tag-editor {
    margin-bottom: 10px; }
  .tag-editor .tag-editor-tag {
    height: 5.8vh;
    line-height: 5.8vh; }
  .tag-editor .tag-editor-delete {
    height: 5.8vh; }
  .tag-editor .tag-editor-delete i:before {
    line-height: 5.8vh; } }

@media only screen and (max-device-width: 1024px) {
  .form-container {
    height: auto;
    min-height: inherit; }
  .form-container.subscription-container {
    min-height: inherit;
    height: auto; }
  .form-container input[type=text],
  .form-container input[type=email],
  .form-container input[type=tel],
  .form-container input[type=password],
  .form-container textarea {
    height: 60px;
    font-size: 18px;
    margin: 10px 0; }
  .form-container p {
    margin-bottom: 10px; }
  .form-container.subscription-container h2 {
    padding-top: 20px; }
  .step-indicator {
    height: auto; }
  .step-indicator .stage {
    height: 60px; }
  .tag-editor {
    margin-bottom: 20px; }
  .tag-editor .tag-editor-tag {
    height: 40px;
    line-height: 40px; }
  .tag-editor .tag-editor-delete {
    height: 40px; }
  .tag-editor .tag-editor-delete i:before {
    line-height: 20px; } }

@media screen and (max-height: 400px) {
  .intro-section {
    height: 100vh; }
  .intro-section.subpage {
    height: 55vh; }
  .intro-section.subpage .page-title .page__title {
    font-size: 35px; }
  .responsive-navigation .menu .menu li {
    height: 20px; }
  .responsive-navigation .menu li {
    height: 40px; } }
