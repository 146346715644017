.intro-section.subpage .flex-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 100px);
}

.intro-section.subpage .page-title .page__title {
  font-size: 65px;
  font-family: 'sofia-pro-soft', Helvetica, sans-serif;
}

// Content section styling
.content-section .flex-container {
  display: flex;
  margin-top: 40px;
}

.content-section .main-content {
  margin-bottom: 80px;
  max-width: 100%;
  margin-left: 80px;
  flex: 1;
}

.content-section .main-content h1,
.content-section .main-content h2,
.content-section .main-content h3,
.content-section .main-content h4,
.content-section .main-content h5,
.content-section .main-content h6 {
  margin-bottom: 10px;
  color: $primary-color;
}

.content-section .main-content h1 {
  font-size: 26px;
}
.content-section .main-content h2 {
  font-size: 24px;
}
.content-section .main-content h3 {
  font-size: 22px;
}
.content-section .main-content h4 {
  font-size: 20px;
}
.content-section .main-content h5 {
  font-size: 18px;
}
.content-section .main-content h6 {
  font-size: 16px;
}

.content-section .main-content img {
  max-width: 100%;
  height: auto;
}

.content-section .image-container {
  margin: 20px 0;
  display: flex;
  justify-content: center;
  flex-flow: row wrap;
}

.content-section .image-container .image-box {
  margin: 0 20px;
  flex-shrink: 0;
}

.content-section .image-container .image-box img {
  max-width: 100%;
  height: auto;
}

.sidebar-navigation, .sidebar-card {
  background: #ffffff;
  border-radius: 10px;
  margin-bottom: 40px;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
}

.subpage-sidebar {
  width: 280px;
  flex-shrink: 0;
}

.hidden-desktop {
  display: none;
}

.sidebar-navigation ul {
  padding-left: 0;
}

.sidebar-navigation li {
  list-style-type: none;
  height: 60px;
  border-bottom: 1px solid #ebebeb;
  a {
    display: block;
    width: 100%;
    height: 100%;
    align-items: center;
    display: flex;
    padding-left: 20px;
  }
  &:hover, &.active {
    background: $primary-color;
    a {
      color: #ffffff;
    }
  }
}

.sidebar-navigation li:first-child {
  border-radius: 10px 10px 0 0;
}

.sidebar-navigation li:last-child {
  border-bottom: none;
  border-radius: 0 0 10px 10px;
}

.sidebar-card {
  padding: 40px 20px;
  text-align: center;
}

.sidebar-card h1,
.sidebar-card h2,
.sidebar-card h3,
.sidebar-card h4,
.sidebar-card h5,
.sidebar-card h6 {
  font-size: 20px;
  margin-bottom: 10px;
}

.sidebar-card p {
  margin-bottom: 20px;
}

.sidebar-card .button {
  margin-bottom: 20px;
}

.sidebar-card .button:last-child {
  margin-bottom: 0;
}

#messages--error p {
  margin-bottom: 5px;
  padding: 10px 20px;
  background: $tertiary-color;
  color: #ffffff;
}

#messages--error em {
  font-family: 'Karlo Sans Regular', 'Helvetica Neue Light', Helvetica, Arial, sans-serif;
}