.button, input[type=submit].button, input[type=submit] {
  height: 60px;
  min-width: 220px;
  line-height: 60px;
  -webkit-appearance: none;
  display: block;
  text-decoration: none;
  text-transform: uppercase;
  color: #ffffff;
  border: none;
  font-size: 16px;
  border-radius: 10px;
  text-align: center;
  padding: 0;
  cursor: pointer;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);
  transition: $default-transition background;
  font-family: 'sofia-pro-soft', Helvetica, sans-serif;
  &.orange {
    background-color: $tertiary-color;
    &:hover {
      background-color: darken($tertiary-color, 5%);
    }
  }
  &.hollow {
    border: 2px solid #ffffff;
    line-height: 58px;
    transition: $default-transition all;
    &:hover {
      background-color: #ffffff;
      color: $text-color;
    }
    &.hollow-black {
      border: 2px solid $text-color;
      color: $text-color;
    }
  }

  &.green {
    background-color: $secondary-color;
    &:hover {
      background-color: darken($secondary-color, 5%);
    }
  }
  &.has-icon {
    padding-right: 30px;
    background-image: url(../images/arrow-forward.svg);
    background-repeat: no-repeat;
    background-position: 150px center;
  }
  &:focus, &:active {
    outline: none;
  }
}

.link {
  font-size: inherit;
  color: $primary-color;
  text-decoration: none;
  margin-top: 20px;
  display: block;
  &:visited, &:active, &:focus {
    color: $primary-color;
  }
}